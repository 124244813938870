import { useParams, useLoaderData, Link } from "react-router-dom";
import styles from "./Post.module.sass";
import { useState, useEffect, useContext } from "react";
import { createPortal } from "react-dom";
import Comments from "../components/Comments";
import CommentForm from "../components/CommentForm";
import AuthContext from "../context/AuthContext";
import UserModal from "../components/UserModal";
import Backdrop from "../components/Backdrop";
import DoubleSeparator from "../components/UI/DoubleSeparator";
import DOMPurify from "dompurify";

const Post = () => {

    const authCtx = useContext(AuthContext);
    const { isLoggedIn, isLoading } = authCtx;
   

    let { post } = useLoaderData();
    const [comments, setComments] = useState(post.comments);
    const [ portalOpen, setPortalOpen ] = useState( false )

    const updateComments = (newComment) => {
        setComments([...comments, newComment]);
    }

    const openPortal =  () => {
        document.body.style.overflow = 'hidden'
        setPortalOpen(true)
    }

    const closePortal = () => {
        document.body.style.overflowY = 'scroll'
        setPortalOpen(false)
    }

    if (isLoading) {    
        return <p>Loading...</p>;
    }

    return (
        <div className={styles.container}>
            <article className={styles.postArticle}>
                
                    <h2 className={styles.postTitle}>{post.title}</h2>
                    <img className={styles.postImage} src={post.image} alt="The picture of the post" />
                    <DoubleSeparator />
                    <div className={styles.post}>
                    <p><em>{post.author}</em></p>
                    <p>{post.date}</p>
                    <div className = {styles.content} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content,
                        { ADD_TAGS: ['iframe'], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'src', 'title', 'className', 'style'] }
                    ) }} />
                    </div>

        
            </article>

            <DoubleSeparator />

            <section className = {styles.comments} id="comments">
                {isLoggedIn ? <CommentForm updateComments = {updateComments} postId = {post.id}/> 
                : <p className={styles.pleaseLoginLine}>Please <span className = {styles.loginLink} onClick = {openPortal}>login</span> to leave a comment</p>}
            
            <DoubleSeparator />
                
            <Comments comments={comments} />
                {portalOpen && createPortal(<UserModal 
                                        modalType= "login"
                                        openPortal={openPortal} 
                                        closePortal={closePortal} 
                                        userName=""/>, 
                                        document.getElementById('loginportal'))}
            {portalOpen && createPortal(<Backdrop />, document.getElementById('loginbackdrop'))}
            </section>
        </div>
    );
};

export default Post;
