import { NavLink } from 'react-router-dom';
import styles from './NavigationBar.module.sass';


const NavigationBar = () => {
    
    return (
        <nav>
        <ul className={styles.navigationList}>
            <li><NavLink className={({isActive}) => isActive ? styles.active 
                                                                : styles.link} 
                                                                to="/">Home</NavLink></li>
            <li><NavLink className={({isActive}) => isActive ? styles.active 
                                                                : styles.link} to="about">About</NavLink></li>
            <li><NavLink className={({isActive}) => isActive ? styles.active 
                                                                : styles.link} to="posts">Post list</NavLink></li>
            <li><NavLink className={({isActive}) => isActive ? styles.active 
                                                                : styles.link} to="impressum">Impressum</NavLink></li>
        </ul>
    </nav>
    );
};

export default NavigationBar;