import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import UserModal from '../components/UserModal';
import Backdrop from '../components/Backdrop';
import { Link } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import CustomCookieConsent from '../components/UI/CustomCookieConsent';

import AuthContext from '../context/AuthContext';


import styles from './Root.module.sass';

const Root = ({viewport}) => {

    const [scrollY, setScrollY] = useState(0);
    const authCtx = useContext(AuthContext);
    const [userName, setUserName] = useState("");
    const [portalOpen, setPortalOpen] = useState(false);
    const [modalType, setModalType] = useState('userChoice');
    const [storedIsLoggedIn, setStoredIsLoggedIn] = useState(false);

    useEffect(() => {
            setUserName(localStorage.getItem("userName"));
            setStoredIsLoggedIn(localStorage.getItem("isLoggedIn") === "true");
        }, [authCtx.isLoggedIn, userName]);
 

    const openPortal = () => {
        setPortalOpen(true);
    }

    const closePortal = () => {
        setPortalOpen(false);
    }

    const handleLogout = () => {
        authCtx.logout();
        setStoredIsLoggedIn(localStorage.getItem("isLoggedIn") === "true");

    }

    const handleLogin = () => { 
        setModalType('login');
        openPortal();
    }

    const handleRegister = () => {
        setModalType('register');
        openPortal();
    }

    const openUserPortalHandler = () => {
        setModalType('userChoice');
        openPortal();
    }

    window.addEventListener('scroll', () => {
        setScrollY(window.scrollY);
    });


    return (
        <div className = {portalOpen ? styles.container : styles.site}>
            <Header scroll = {scrollY} 
                    storedIsLoggedIn={storedIsLoggedIn}
                    handleLogin={handleLogin}
                    handleRegister={handleRegister}
                    openUserPortalHandler={openUserPortalHandler}
                    userName = {userName}
                    handleLogout={handleLogout}
                    viewport = {viewport}
                    />

            <Outlet />  
            {portalOpen && createPortal(<UserModal 
                                        modalType={modalType} 
                                        openPortal={openPortal} 
                                        closePortal={closePortal} 
                                        userName={userName}/>, 
                                        document.getElementById('portal'))}
            {portalOpen && createPortal(<Backdrop />, document.getElementById('backdrop'))}
       
            
            <Footer storedIsLoggedIn={storedIsLoggedIn}
                    handleLogin={handleLogin}
                    handleRegister={handleRegister}
                    openUserPortalHandler={openUserPortalHandler}
                    userName = {userName}
                    handleLogout={handleLogout}
                    viewport = {viewport} />
            
            <CustomCookieConsent />
            
        </div>
    );
};

export default Root;