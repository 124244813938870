import React, { useRef, useState, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import styles from './CommentForm.module.sass';

const CommentForm = ({updateComments, postId}) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const authCtx = useContext(AuthContext);
    const [comment, setComment] = useState('');
    const [feedback, setFeedback] = useState('');

    const commentRef = useRef();

    const fetchToPostComment = async (comment) => {
        const response = await fetch(`${API_URL}/blog/comment-create/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authCtx.accessToken}`,
            },
            body: JSON.stringify({ post: postId,
                                    comment: comment,
                                    user: authCtx.userName,
                                    date: new Intl.DateTimeFormat('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date()),
                                 }),
        });
        return response;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        setComment(commentRef.current.value);
        // Validate comment
        if (comment.trim() === '') {
            setFeedback('Please enter a comment.');
            return;
        }

        // Validate dangerous characters
        const dangerousCharactersRegex = /[<>&/]/;
        if (dangerousCharactersRegex.test(comment)) {
            setFeedback('Invalid characters in comment. Try again');
            return;
        }

        try {
        let response = await fetchToPostComment(comment);
        if (!response.ok) {
            authCtx.refresh();
            response = await fetchToPostComment(comment);
            }
        if (response.ok) {
            const data = await response.json();
            setFeedback('Comment submitted successfully!');
            updateComments(data);
        } else {
            setFeedback('Comment not posted. Please try again.');
        }
        } catch (error) {
            setFeedback('An error occurred. Please try again.');
        }

        setTimeout(() => {
            setFeedback('');
            commentRef.current.value = '';
        }, 5000);
        
    };

    return (
        <div className={styles.commentForm}>
            <form onSubmit={handleSubmit}>
                <div className={styles.formField}>
                <label htmlFor="comment">Your Comment:</label>
                <textarea styles = {styles.commentTextArea}
                    ref={commentRef}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                ></textarea>
                </div>
                <div className={styles.buttons}>
                <button type="submit">Submit</button>
                </div>
            </form>
            {feedback && <p>{feedback}</p>}
        </div>
    );
};

export default CommentForm;