import React, { useRef, useState, useContext } from 'react';
import styles from './Login.module.sass';


import AuthContext from '../context/AuthContext';

const Login = ({closePortal, forgotPassword}) => {

    const API_URL = process.env.REACT_APP_API_URL;
    const usernameRef = useRef();
    const passwordRef = useRef();
    const [errorMessage, setErrorMessage] = useState('');
    const [feedback, setFeedback] = useState('');
    const [logoutFeedback, setLogoutFeedback] = useState('');
    const authCtx = useContext(AuthContext);



    const logUserIn = async (username, password) => {
        try {
            const response = await fetch(`${API_URL}/login/api/token/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            })
            if (response.ok) {
                const dejsonedResponse = await response.json();
                authCtx.login(username, dejsonedResponse.access, dejsonedResponse.refresh);
                setFeedback('Login successful!');            }
            else {
                setErrorMessage('Login failed.');
                authCtx.logout();
                setTimeout(resetFields, 5000);
            }
        } catch (error) {
            setErrorMessage(`Login failed. Error: ${error}. Please try again.`);
            authCtx.logout();
            setTimeout(resetFields, 5000);
        }
    }

    const handleLogin = (event) => {
        event.preventDefault();
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        const dangerousCharacters = /[!#$%^&*()+\=[\]{};':"\\|,<>/?]/;
    
        // Perform validation here

        // Example validation: check if username and password are not empty
        if (!username || !password) {
            setErrorMessage('Please enter both username and password');
            return;
        }

        if (dangerousCharacters.test(username) || dangerousCharacters.test(password)) {
            setErrorMessage('Only letters, numbers, hyphens and underscores allowed. Try again');
            setTimeout(resetFields, 5000);
            return;
        }

        logUserIn(username, password);

    };

    const handleLogout = () => {
        authCtx.logout();
        setLogoutFeedback('Logout successful!');
    }

    const resetFields = () => {
        usernameRef.current.value = '';
        passwordRef.current.value = '';
        setErrorMessage('');
    };

    return (
        <div className={styles.loginForm}>
            {!feedback &&
            <>
            <h2 className={styles.loginTitle}>Login</h2>
            
            <form>
            <div className={styles.formField}>
                <label htmlFor="username">Username:</label>
                <input type="text" id="username" ref={usernameRef} />
            </div>
            <div className={styles.formField}> 
                <label htmlFor="password">Password:</label>
                <input type="password" id="password" ref={passwordRef} />
            </div>
            {errorMessage && <p>{errorMessage}</p>}
            <div className={styles.buttons}>
            <button type = "submit" onClick={handleLogin}>Login</button>    
            <button type="button" onClick={() => closePortal()}>Close</button>
            <button type="button" onClick={() => forgotPassword()}>Forgot password?</button>
            </div>
            </form>
            </>
        }
            
            {!logoutFeedback && feedback && <h3 className={styles.feedback}>{feedback}</h3>}
            {(feedback && !errorMessage) && 
                    <>
                    
                    <h3 className={styles.feedback}>{logoutFeedback}</h3>
                    {!logoutFeedback && <button className = {styles.logoutAfterLoginButton} type="button" onClick={() => closePortal()}>Close</button>}
                    </>
            }
            
        </div>
    );
};

export default Login;