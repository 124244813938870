import { useRef, useState, useContext } from 'react';
import styles from './ChangePassword.module.sass';
import AuthContext from '../context/AuthContext';

const ChangePassword = ({changePasswordFeedback}) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const authCtx = useContext(AuthContext);

    const [feedback, setFeedback] = useState('');
    
    const oldPasswordRef = useRef();
    const newPasswordRef = useRef();
    const confirmPasswordRef = useRef();

    const fetchRequestToChangePassword = async (old_password, new_password) => {
        const response = await fetch(`${API_URL}/login/api/change-password/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify({old_password, new_password})
        });
        return response
    }

    const changePasswordHandler = async (event) => {
        event.preventDefault();
        let old_password = oldPasswordRef.current.value;
        let new_password = newPasswordRef.current.value;
        let confirmPassword = confirmPasswordRef.current.value;
        if (new_password !== confirmPassword) {
            setFeedback("Passwords do not match. Try again.");
            return;
        }
        if (new_password.length < 8) {
            setFeedback("Password must be at least 8 characters long. Try again.");
            return;
        }
        if (!old_password || !new_password || !confirmPassword) {
            setFeedback("Please fill in all fields.");
            return;
        }
        let response = await fetchRequestToChangePassword(old_password, new_password);
        if (!response.ok && response.status === 401) {
            authCtx.refresh();
            response = await fetchRequestToChangePassword(old_password, new_password);
        }
        if (response.ok) {
            changePasswordFeedback();
        } else if (response.status === 403) {
            setFeedback("Failed to change password. Please check your old password again.");
        } else {
                setFeedback("Failed to change password.");
        }
    }

        return(
            <div className={styles.changePasswordForm}>
                <h2 className={styles.changePasswordTitle}>Change Password</h2>
            <form>
            <div className={styles.formField}>
            <label htmlFor="oldPassword">Old password</label>
            <input type="password" id="oldPassword" ref={oldPasswordRef}/>
            </div>
            <div className={styles.formField}>
            <label htmlFor="password">New password</label>
            <input type="password" id="password" ref={newPasswordRef}/>
            </div>
            <div className={styles.formField}>
            <label htmlFor="confirmPassword">Confirm password</label>
            <input type="password" id="confirmPassword" ref={confirmPasswordRef}/>
            </div>
            <div className={styles.buttons}>
            <button type="submit" onClick={changePasswordHandler}>Submit</button>
            </div>
            <div className={styles.feedback}>{feedback}</div>
            </form>
            </div>
        )
    }

export default ChangePassword;