import { useLoaderData, Link } from "react-router-dom";
import { useState, useEffect } from "react";

import TagList from "../components/TagList";
import DateList from "../components/DateList";
import styles from "./Posts.module.sass";

const Posts = ({viewport}) => {
  let { posts } = useLoaderData();
  let [tag, setTag] = useState("");
  let [date, setDate] = useState("");
  let [filteredPosts, setFilteredPosts] = useState(posts);
  let [isFiltered, setIsFiltered] = useState(false);
  let [sortedSubtitle, setSortedSubtitle] = useState("");

  useEffect(() => {
    let updatedPosts = posts;

    if (tag) {
      updatedPosts = updatedPosts.filter(post => post.tags.some(t => t.name === tag));
    }

    if (date) {
      updatedPosts = updatedPosts.filter(post => post.date.substring(0, 4) === date);
    }

    setFilteredPosts(updatedPosts);
  }, [tag, date, posts]);

  const addTagFilter = (selectedTag) => {
    setTag(selectedTag);
    setIsFiltered(true);
    let message = "";
    if (selectedTag) {
        message = `tag: ${selectedTag}`;
    }
    if(date) {
        message += message ? `, year: ${date}` : `year: ${date}`;
    }
    setSortedSubtitle(message);
};

  const addYearFilter = (year) => {
    setDate(year);
    setIsFiltered(true);
    let message = "";
    if (year) {
        message = `year: ${year}`;
    }
    if(tag) {
        message += message ? `, tag: ${tag}` : `tag: ${tag}`;
    } 
    setSortedSubtitle(message);
  };

  const removeFilter = () => {
    setTag("");
    setDate("");
    setIsFiltered(false);
    setSortedSubtitle("");
  };

  const stripHtmlTags = (html) => { 
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  return (
    <>
    <div className={styles.mainContainer}>
        <div className={styles.postContainer}>
          <div className={styles.header}>
          <h2 className={styles.mainTitle}>Posts</h2>
          </div>
            {isFiltered && <h3 className={styles.sortedSubtitle}>Sorted by {sortedSubtitle} - <a href='#' onClick={removeFilter}>(RESET)</a> </h3>}
            <section className={styles.container}>
            <div className={styles.posts}>
              {filteredPosts.map((post) => (
              <div key={post.slug}>
                <article className={styles.post}>
                <h2 className={styles.postTitle}>
                  <strong>{post.title}</strong>
                </h2>
                <img
                  className={styles.postImage}
                  src={post.image}
                  alt="The picture of the post"
                />
                  <p>
                  Author: <em>{post.author}</em>
                  </p>
                  <p>Published: {post.date}</p>
                
                   <p>{stripHtmlTags(post.content).split(" ").slice(0, 10).join(" ")} ...</p>
                  <Link className={styles.readMoreLink} to={`/post/${post.slug}`}>
                    Read more...
                  </Link>
                </article>
                <hr />
              </div>
          ))}
        </div>
      </section>
      </div>
      {viewport > 430 &&
      <div className={styles.sidebar}>
          <TagList posts={posts} setTag={addTagFilter} reset={removeFilter} chosenTag={tag} chosenYear={date}/>
          <DateList posts={posts} setDate={addYearFilter} reset={removeFilter} chosenYear={date} chosenTag={tag}/>
        </div>
        }
      </div>
    </>
  );
};

export default Posts;
