    // TagList Component
    import styles from './TagList.module.sass';

    import { useState, useEffect } from 'react';

    const TagList = (props) => {
        const [tags, setTags] = useState([]);

        useEffect(() => {
            // Extract unique tags from posts
            const uniqueTags = props.posts
                .map(post => post.tags.map(tag => tag.name))
                .flat()
                .filter((value, index, self) => self.indexOf(value) === index);
            setTags(uniqueTags);
        }, [props.posts]);

        const chooseTag = (tag) => {
            if(tag === props.chosenTag && props.chosenYear === "") {
                props.reset();
            } else if(tag === props.chosenTag && props.chosenYear !== "") {
                props.setTag("");
            }
            else {
                props.setTag(tag);
            }
        }

        return (
            <section className={styles.tags}>
                <h3>Tags:</h3>
                <ul>
                    {tags.map((tag) => (
                        <li className={(props.chosenTag && tag === props.chosenTag) ? styles.chosenTagLink : styles.tagLink } key={tag}>
                            <a onClick={() => chooseTag(tag)}>{tag}</a>
                        </li>
                    ))}
                </ul>
            </section>
        );
    }

    export default TagList;