import styles from "./Comments.module.sass";
import DoubleSeparator from "./UI/DoubleSeparator";
import SingleSeparator from "./UI/SingleSeparator";

const Comments = (props) => {

  return (
    
    <div className={styles.commentsBlock}>
      <h2 className={styles.commentsTitle}>Comments</h2>
        <div>
        {props.comments.length > 0 ?
          props.comments.map(comment => (
            <div className={styles.comment} key={comment.comment}>
              <h4 className= {styles.user}>{comment.user}</h4>
              <h5>{comment.date}</h5>
              <p>{comment.comment}</p>
              <SingleSeparator />
            </div>
          ))
        :
          <p>No comments yet. Be the first to comment!</p>}
        </div>
    </div>
  );
}

export default Comments;