import React, { useState } from 'react';
import styles from './Register.module.sass';

const Register = ({closePortal}) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [feedback, setFeedback] = useState('');
    const feedbackParts = feedback.split('- ')

    const registerUser = async (username, password, email) => {
        try {
            const response = await fetch(`${API_URL}/login/api/register/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password, email })
            })
            if (response.ok) {
                setFeedback('Registration successful! - You can now log in with your new account.');
            }
            else {
                setFeedback('Registration failed. Please try again.');
                setTimeout(resetFields, 5000);
            }
        }
        catch (error) {
            setFeedback(`Registration failed. Error: ${error}. Please try again.`);
            setTimeout(resetFields, 5000);
        }
    }


    const handleRegister = (event) => {
        event.preventDefault();

        // Check for empty fields
        if (!username || !password || !email) {
            setErrorMessage('Please fill in all fields');
            return;
        }
        

        // Check for dangerous characters
        const dangerousCharacters = /[!#$%^&*()+\=[\]{};':"\\|,<>/?]/;
        if (dangerousCharacters.test(username) || dangerousCharacters.test(password) || dangerousCharacters.test(email)) {
            setErrorMessage('Only letters, numbers, hyphens and underscores allowed. Try again');
            setTimeout(resetFields, 5000);
            return;
            }
            

        registerUser(username, password, email);

        }



    const resetFields = () => {
        setUsername('');
        setPassword('');
        setEmail('');
        setErrorMessage('');
    }

    return (
        <div className={styles.registerForm}>
        <h2 className={styles.registerTitle}>Register</h2>
        <p>Registered users will be able to comment posts.</p>
            { !feedback ?
        <div>
            
            {errorMessage && <p>{errorMessage}</p>}
            <form>
                <div className = {styles.formField}>
                <label htmlFor='username'>
                    Username
                </label>
                <input name="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />

                </div>
                <div className = {styles.formField}>
                <label htmlFor="password">
                    Password
                    </label>
                    <input name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
               
                </div>
                <div className = {styles.formField}>
                <label htmlFor="email">
                    Email
                    </label>
                    <input name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
               
                </div>
                <div className={styles.buttons}>
                <button type="submit" onClick={handleRegister}>Register</button>
                <button type="button" onClick={ () => closePortal() }>Close</button>
                </div>
                
            </form>
        </div>
         :
         <>
         <h3 className={styles.feedback}>{feedbackParts[0]}</h3>
         <p> {feedbackParts[1]}</p>
         </>
            }
    </div>
            
    );

   
};

export default Register;