import React from "react";
import { useNavigate } from "react-router-dom";
import styles from './NotFound.module.sass'

const NotFound = () => {

    const navigate = useNavigate();

    const onBackToHomeHandler = () => {
        navigate("/");
    }
    
    return (
        <div className={styles.container}>
            <h1>404 - Not Found</h1>
            <h3>It looks like you are lost...</h3>
            <button onClick={onBackToHomeHandler}>Back to home</button>
        </div>
        );
}

export default NotFound;