import React, { useRef, useState, useContext } from 'react';
import styles from './UserPortal.module.sass';

const UserPortal = ({userName, changePassword, deleteAccount, logout}) => {
    return (
        <div className={styles.userPortal}>
                <h3>Username:</h3>
                <h2>{userName}</h2>
                <div className={styles.buttons}>
                <button onClick = {() => changePassword()} type="button">Change password</button>
                <button onClick={() => deleteAccount()} type="button">Delete Account</button>
                <button onClick={() => logout()} type="button">Logout</button>
                </div>
                </div>
    )
        }

export default UserPortal;