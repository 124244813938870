import React, { useRef, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './ForgotPassword.module.sass';
import AuthContext from '../context/AuthContext';

const ForgotPassword = ({closePortal, toLogin}) => {

const API_URL = process.env.REACT_APP_API_URL;
const usernameOrEmailRef = useRef();
const newPasswordRef = useRef();
const [errorMessage, setErrorMessage] = useState('');
const [feedback, setFeedback] = useState('');
const authCtx = useContext(AuthContext);


const fetchRequestToResetPassword = async (usernameOrEmail, password) => {
    const response = await fetch(`${API_URL}/login/api/reset-password/`, {
        method: "PATCH", 
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( {username_or_email: usernameOrEmail, new_password: password})
            });
        return response;
        };



const handleResetPassword = async (event) => {

    event.preventDefault();
    const username = usernameOrEmailRef.current.value;
    const password = newPasswordRef.current.value;
    const dangerousCharacters = /[!#$%^&*()+\=[\]{};':"\\|,<>/?]/;

    // Perform validation here

    // Example validation: check if username and password are not empty
    if (!username || !password) {
        setErrorMessage('Please enter both username/email and password');
        return;
    }

    if (dangerousCharacters.test(username) || dangerousCharacters.test(password)) {
        setErrorMessage('Only letters, numbers, hyphens and underscores allowed. Try again');
        setTimeout(resetFields, 5000);
        return;
    }

    try {
        const response = await fetchRequestToResetPassword(username, password);
        const message = await response.json()
        if (!response.ok) {
            setErrorMessage(message.message)
        } else {
            setFeedback(message.message);

        }
    } catch(error) {
        setErrorMessage("Something went wrong. Try again later!")
    }
    
    authCtx.logout()
}

const resetFields = () => {
    usernameOrEmailRef.current.value = '';
    newPasswordRef.current.value = '';
    setErrorMessage('');
};


    return (
        <div className={styles.forgotPasswordForm}>
            {!feedback &&
            <>
            <h2 className={styles.forgotPasswordTitle}>Password reset</h2>
            <p>Please input your username or your email and a new password.</p>
            
            <form>
            <div className={styles.formField}>
                <label htmlFor="username">Username or email:</label>
                <input type="text" id="username" ref={usernameOrEmailRef} />
            </div>
            <div className={styles.formField}>
                <label htmlFor="password">New password</label>
                <input type="password" id="password" ref={newPasswordRef} />
            </div>
            
            {errorMessage && <p>{errorMessage}</p>}
            <div className={styles.buttons}>
            <button type = "submit" onClick={handleResetPassword}>Submit</button>    
            <button type="button" onClick={() => closePortal()}>Close</button>
            </div>
            </form>
            </>
        }
            
            {(feedback && !errorMessage) && 
                    <>
                    <h3 className={styles.feedback}>{feedback}</h3>
                    <button type="button" onClick={() => toLogin()}>To login</button>
                    </>
            }
            </div>
    )
}

export default ForgotPassword;