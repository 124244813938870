import styles from './SingleSeparator.module.sass'

const SingleSeparator = () => {
  return (
    <div className={styles.singleSeparator}>
        <hr className= {styles.smallSeparator}/> 
    </div>
  );
}

export default SingleSeparator;