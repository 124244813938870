import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { useState } from "react";
import Root from './pages/Root';
import Home from './pages/Home';
import About from './pages/About';
import Post from './pages/Post';
import Posts from './pages/Posts';
import Impressum from './pages/Impressum';
import NotFound from './pages/NotFound';

function App() {

  const API_URL = process.env.REACT_APP_API_URL;

  const[ viewportWidth, setViewportWidth ] = useState(window.innerWidth);

  window.addEventListener("resize", () => {setViewportWidth(window.innerWidth)});

  const fetchPosts = async () => {
    const response = await fetch(`${API_URL}/blog/posts/`, 
    {
      method: "GET",
      headers: {
        "Content-Type": "application-json"
      },
    }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch posts");
    } else {
      const posts = await response.json();
      return {posts};
    }
  }

  const router = createBrowserRouter([
   {
    path:"/",
    element: <Root viewport={viewportWidth}/>,
    children: [
    {
      index: true,
      element: <Home viewport={viewportWidth}/>,
      errorElement: <NotFound viewport={viewportWidth}/>,
      loader: fetchPosts
    },
    {
      path: "about",
      element: <About viewport={viewportWidth}/>
    },
    {
      path: "post/:slug",
      element: <Post viewport={viewportWidth}/>,
      errorElement: <NotFound viewport={viewportWidth}/>,
      loader: async ({params}) => {
        const response = await fetch(`${API_URL}/blog/posts/`, 
          {
            method: "GET",
            headers: {
              "Content-Type": "application-json"
            },
          }
          );
        if (!response.ok) {
          throw new Error("Failed to fetch posts");
        } else {
          const posts = await response.json();
          const post = posts.find(post => post.slug === params.slug);
          return {post};
        }
      }
    },
    {
      path: "posts",
      element: <Posts viewport={viewportWidth}/>,
      errorElement: <NotFound viewport={viewportWidth}/>,
      loader: fetchPosts
    },
    {
      path: "impressum",
      element: <Impressum viewport={viewportWidth}/>
    }
  ]}
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
