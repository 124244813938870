// DateList Component
import styles from './DateList.module.sass';

import { useState, useEffect } from 'react';

const DateList = (props) => {
    const [years, setYears] = useState([])

    useEffect(() => {
        // Extract unique years from post dates
        const uniqueYears = props.posts
            .map(post => post.date.substring(0, 4))
            .filter((value, index, self) => self.indexOf(value) === index);
        setYears(uniqueYears);
    }, [props.posts]);

    const chooseYear = (year) => {
        if(year === props.chosenYear && props.chosenTag === "") {
            props.reset();
        } else if(year === props.chosenYear && props.chosenTag !=="") {
            props.setDate("");
        }
        else {
            props.setDate(year);
        }
    }

    return (
        <section className={styles.dates}>
            <h3>Years:</h3>
            <ul>
                {years.map((year) => (
                    <li className={(props.chosenYear && year === props.chosenYear) ? styles.chosenDateLink : styles.dateLink } key={year}>
                        <a onClick={() => chooseYear(year)}>{year}</a>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default DateList;