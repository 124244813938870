import React from "react";
import styles from "./About.module.sass";
import avatar from "../assets/alessio_avatar.png";
const About = () => {
  return (
    <section className={styles.container}>
      <h1 className={styles.title}>About this blog</h1>
      <img
        className={styles.avatar}
        src={avatar}
        alt="Alessio, the author of this blog"
      />
      <article className={styles.presentation}>
      <p>Let's just ask the one and only question that really matters on this site.<br />
Why, in the year 2024 AD, still a BLOG?</p>
<p>I mean, we live in the era of Tik Tok, Instagram and other mass social media and whatnot, of fast and chaotic information. An era of uncertainty and stress, where information flows madly and uncontrollably and plethorically in all its (pseudo) communicative manifestations, be it a Whatsapp feed, the daily endless scrolling, likes, "love it!" messages under fleeting posts that entertain you for a split second and then disappear into the black hole of archives that interest no one and feed big data collectors. The network has to be fast and fleeting, it has to be measured in big numbers, hundreds of likes, thousands of friends, hundreds of thousands of subscribers, billions of users you can spam with your influencer enterprise. It has to distract and entertain. It has to be emotional, it has to change and give you new content like a slot machine every time you push the button. It has to lure you with something, new contacts, recognition, the dream of prestige, the promise of sex, the opportunity to experience something you can use for yourself every time you swipe or glance at your smartwatch?
Isn't a personal blog just a gigantic, hopeless waste of time? Nobody has any reason to read it. It is slow, it is static, it is hopelessly text-based, it gives you just one opinion in a sea of opinions. And no one who wants to write deep information has any hope of keeping up with the pace of the modern consumer's greedy need for novelty. Not without making it a full-time job (unpaid, of course, since it cannot cost money), and probably not even then.<br />
So, why?</p>

<p>Well, let's think about it: no one has any reason to read it. So it is my space. It is my house, it can be slow and deep and political and serious and philosophical and informative and whatever I want it to be. And that is beautiful. A blog, in 2024, is just a powerless little thing, and that means it doesn't have to live up to any standards. it can be a truly free space. And that is what this blog will be. It will be what blogs were born to be: a WEB LOG, a diary, a testimony, however small and insignificant, of the existence of the thinking of a human being.</p>
<p>I am a person who holds sacred the importance of thinking freely and deeply, of reading, analyzing and discussing, of loving and celebrating people and things, and of being deeply, deeply involved in what one believes. So this is just a space where I will do that and celebrate that when I feel up to it. I will do it in my own way and at my own pace. It will be a place for politics, for ideas, for technological enthusiasm, for environmentalism, for culture, for creation. It will not necessarily be up to date. I may go years without writing anything. Maybe I will write many things at once. I don't know. I just love it to be a place where I can exist spiritually, alone or together with anyone who shares some interest, just by reading or commenting. I like the idea that the Internet still has a place to do these things. </p>
<p>This is a relaunch, for the first time self-programmed and self-hosted, of my old 2019 blog at Blogspot. I will be reposting some of my old articles and starting over with some new ideas. Have fun reading and happy blogging!</p>
      </article>
    </section>
  );
};

export default About;
