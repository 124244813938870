import { createContext, useState, useEffect } from "react";

const AuthContext = createContext({
    isLoggedIn: false,
    userName: "",
    accessToken: "",
    refreshToken: "",
    login: (user, access, refresh) => {},
    logout: () => {},
    refresh: () => {},
    isLoading: true,
});

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const storedUserLoggedInInformation = localStorage.getItem("isLoggedIn") === "true";
        const storedUserName = localStorage.getItem("userName") || "";
        const storedAccessToken = localStorage.getItem("accessToken") || "";
        const storedRefreshToken = localStorage.getItem("refreshToken") || "";

        setIsLoggedIn(storedUserLoggedInInformation);
        setUserName(storedUserName);
        setAccessToken(storedAccessToken);
        setRefreshToken(storedRefreshToken);
        setIsLoading(false);
    }, []);

    const login = (user, access, refresh) => {
        setIsLoggedIn(true);
        setUserName(user);
        setAccessToken(access);
        setRefreshToken(refresh);
        localStorage.setItem("userName", user);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);
    };

    const logout = () => {
        setIsLoggedIn(false);
        setUserName("");
        setAccessToken("");
        setRefreshToken("");
        localStorage.setItem("isLoggedIn", "false");
        localStorage.removeItem("userName");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
    };

    const refresh = () => {
        const refresh = localStorage.getItem("refreshToken");
        fetch(`${process.env.REACT_APP_API_URL}/login/api/token/refresh/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Failed to refresh token");
            })
            .then((data) => {
                localStorage.setItem("accessToken", data.access);
                setAccessToken(data.access);
            })
            .catch((error) => {
                console.error(error);
                logout();
            });
    };

    const contextValue = {
        isLoggedIn,
        userName,
        accessToken,
        refreshToken,
        isLoading,
        login,
        logout,
        refresh,
    };

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;
