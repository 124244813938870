import CookieConsent, { Cookies } from "react-cookie-consent";
import { useState, useEffect } from 'react';
import './customCookieConsent.sass';

const CustomCookieConsent = () => {
    const [showCookieConsent, setShowCookieConsent] = useState(false);
    const [cookieConsentDestroyed, setCookieConsentDestroyed] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    useEffect(() => {
        const userPreference = Cookies.get('user-preference');
        if (!userPreference) {
            setShowOverlay(true);
            setTimeout(() => {
                setShowCookieConsent(true);
                document.body.classList.add('no-scroll');
            }, 500);
        }
    }, []);

    const acceptAllCookies = () => {
        setShowCookieConsent(false);
        Cookies.set('user-preference', 'all', { path: '/' });

        setTimeout(() => {
            
            setShowOverlay(false);
            setCookieConsentDestroyed(true);
            document.body.classList.remove('no-scroll');
        }, 500);
        
    }

    const acceptEssentialCookies = () => {
        setShowCookieConsent(false); 
        Cookies.set('user-preference', 'essential', { path: '/' });
        setTimeout(() => {
            
            setShowOverlay(false);
            setCookieConsentDestroyed(true);
            document.body.classList.remove('no-scroll');
        }, 500);
    }

    return (
        <>
        {showOverlay && <div className="cookie-consent-overlay"></div>}
        <CookieConsent
            location="bottom"
            buttonText="Accept all cookies"
            cookieName="user-preference"
            style={{ background: "#2B373B", alignItems: "center", justifyContent: "space-around", padding: "0 80px", boxSizing: "border-box", fontSize: "16px" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            enableDeclineButton
            hideOnAccept={false}
            declineButtonText="Only necessary cookies"
            onAccept={acceptAllCookies}
            onDecline={acceptEssentialCookies}
            containerClasses={showCookieConsent ? "cookie-consent" : (cookieConsentDestroyed ? "cookie-consent cookie-consent-destroyed": "cookie-consent cookie-consent-hidden" )}
            flipButtons
        >
            This website uses cookies to enhance the user experience.
            <span style={{ fontSize: "10px" }}> Please choose how cookies will be saved.</span>
        </CookieConsent>
        </>
    );
};

export default CustomCookieConsent;
