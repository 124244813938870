import React from 'react';
import styles from './Footer.module.sass';

const Footer = ({ storedIsLoggedIn,
                    handleLogin,
                    handleRegister,
                    openUserPortalHandler,
                    userName,
                    handleLogout,
                    viewport}) => {

    return (
        <footer className={styles.blogFooter}>
            {viewport > 680 &&
            <h3 className={styles.copyright}>Alessio Nocita's Blog - 2024 - All rights reserved</h3>
                }
            {viewport < 680 &&
                (!storedIsLoggedIn ? 
            <>
            <button type="button" className={styles.linkToLogin} onClick={ () => handleLogin() }>Login</button>
            <button type="button" className={styles.linkToLogin} onClick={ () => handleRegister() }>Register</button>
            </>
            :
            
            <div className={styles.welcomeBadge}>
            <h4 className={styles.welcomeBadgeText}>Welcome, <button type="button" className={styles.userName} onClick={openUserPortalHandler}>{userName}!</button></h4>
            <button type="button" className={styles.linkToLogout} onClick={ () => handleLogout() }>Logout</button>
            </div>)
                
                }
            
        </footer>
    );
};

export default Footer;