import React from 'react';
import NavigationBar from './NavigationBar';

import styles from './Header.module.sass';   

const Header = ({scroll,
                storedIsLoggedIn,
                handleLogin,
                handleRegister,
                openUserPortalHandler,
                userName,
                handleLogout,
                viewport}) => {

  
   

    return (
        <header className={scroll < 300 ? styles.head : styles.headRetracted}>

            <h1 className={styles.title}>ALESSIO NOCITA'S BLOG</h1>
            <nav className={styles.loginLinks}>
            {viewport > 680 &&
                (!storedIsLoggedIn ? 
            <>
            <button type="button" className={styles.linkToLogin} onClick={ () => handleLogin() }>Login</button>
            <button type="button" className={styles.linkToLogin} onClick={ () => handleRegister() }>Register</button>
            </>
            :
            
            <div className={styles.welcomeBadge}>
            <h4 className={styles.welcomeBadgeText}>Welcome, <button type="button" className={styles.userName} onClick={openUserPortalHandler}>{userName}!</button></h4>
            <button type="button" className={styles.linkToLogout} onClick={ () => handleLogout() }>Logout</button>
            </div>
                )
            }
            </nav>
            <NavigationBar />
            </header>
    );
};

export default Header;