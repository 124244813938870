import { Link } from "react-router-dom"
import { useRef, useState, useReducer, useContext } from "react"
import styles from './UserModal.module.sass'
import AuthContext from '../context/AuthContext';
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import UserPortal from "./UserPortal";


const UserModal = ({modalType, openPortal, closePortal, userName}) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const authCtx = useContext(AuthContext);

    const userNameRef = useRef();

    // Fetch request functions
   

    const fetchRequestToDeleteAccount = async (username) => {
        const response = await fetch(`${API_URL}/login/api/delete-user/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify({username})
        });
        return response;
    }

    // User feedback
    const [feedback, setFeedback] = useState('');

    // Reducer - used to switch between different modal types
    const [state, dispatch] = useReducer(reducer, {type: modalType});


    // Switch between all different modal types
    function reducer(state, action) {
        switch (action.type) {
            case 'login':
                return {type: 'login'};
            case 'forgotPassword':
                return {type: 'forgotPassword'}
            case 'register':
                return {type: 'register'};
            case 'changePassword':
                return {type: 'changePassword'};
            case 'changePasswordFeedback':
                return {type: 'changePasswordFeedback'};
            case 'deleteAccount':
                return {type: 'deleteAccount'};
            case 'deleteAccountFeedback':
                return {type: 'deleteAccountFeedback'};
            case 'logout':
                return {type: 'logout'};
            default:
                return {type: 'userChoice'};
        }
    }
    

    // Event handlers
    const changePasswordChoiceHandler = () => {
        dispatch({type: 'changePassword'});
    }

    const goToLogin = () => {
        dispatch({type: 'login'})
    }

    const goToForgotPassword = () => {
        dispatch({type: 'forgotPassword'});
    }

    const changePasswordFeedback = () => {
        dispatch({type:'changePasswordFeedback'})
    }

    

    const deleteAccountChoiceHandler = () => {
        dispatch({type: 'deleteAccount'});
    }

    const deleteAccountHandler = async(event) => {
        event.preventDefault();
        let user = authCtx.userName;
        let response = await fetchRequestToDeleteAccount(user);
        if (!response.ok && response.status === 401) {
            authCtx.refresh();
            response = await fetchRequestToDeleteAccount();
        }
        if (response.ok && response.status === 204) {
            authCtx.logout();
        dispatch({type: 'deleteAccountFeedback'});
    } else
        setFeedback("Failed to delete account.");
    }

    const logoutHandler = () => {
        dispatch({type: 'logout'});
    }

   

    return(
        <section className={styles.userModal}>
            <div>
            <nav className= {styles.closeIcon} onClick={() => closePortal() }>[x]</nav>

                {
                state.type === 'login' &&
                <Login closePortal = {closePortal} forgotPassword = {goToForgotPassword}/>
                }

                {state.type === 'forgotPassword' &&
                <ForgotPassword closePortal = {closePortal} toLogin={goToLogin}></ForgotPassword>
                }
                
                {state.type === 'register' &&
                <Register closePortal = {closePortal}/>
                }
                
                {state.type === 'userChoice' && 
                <UserPortal userName={userName} changePassword={changePasswordChoiceHandler} deleteAccount={deleteAccountChoiceHandler} logout={logoutHandler}/>
                }
                {
                state.type === 'changePassword' &&
                <ChangePassword changePasswordFeedback={changePasswordFeedback} />
               }
                {
                state.type === 'changePasswordFeedback' &&
                <>
                <div className={styles.changePasswordFeedback}>
                <p>Your password has been successfully changed</p>
                <button type="button" onClick={() => closePortal()}>Close</button>
                </div>
                </>
                }
                {state.type === 'deleteAccount' &&
                <>
                <div className={styles.deleteAccount}>
                <p>Are you sure you want to delete your account?</p>
                <div className={styles.buttons}>
                <button type="button" onClick={deleteAccountHandler}>Yes, I'm sure.</button>
                <button type="button" onClick={() => closePortal()}>No, I changed my mind.</button> 
                </div>
                <div className={styles.feedback}></div>
                </div>
                </>}

                {
                state.type === 'deleteAccountFeedback' &&
                <>
                <p>Your account has been deleted</p>
                <p>(Important: this will not delete your messages!)</p>
                <button type="button" onClick={()=>closePortal()}>Close</button>
                </>
                }
                {state.type === 'logout' &&
                <>
                <p>You have now been logged out</p>
                </>
                }
                
                

            </div>
        </section>
    )
}

export default UserModal;