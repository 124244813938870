import React from 'react';
import styles from './Impressum.module.sass';
import { useState } from 'react';

function Impressum() {

    const [language, setLanguage] = useState('de');
    return (
        <>
        <div className={styles.buttons}>
        <button onClick = {() => setLanguage('de')} 
        className={language === 'de' ? styles.buttonActive : ""}>Deutsch</button>
            <button onClick = {() => setLanguage('en')}
            className={language === 'en' ? styles.buttonActive : ""}>English</button>
</div>
        <div className = {styles.container} >
          
            <h1>Impressum</h1>
            {language === 'de' &&
            <>
    <p>Angaben gemäß Par. 5 TMG:</p>
    <p>
        Alessio Nocita<br />
        Quergasse 9<br />
        47051 Duisburg
    </p>
    <p>
        Kontakt:<br />
        E-Mail: <a href="mailto:alessio@nocita.com">alessio@nocita.com</a>
    </p>
    <p>Verantwortlich für den Inhalt nach Par. 55 Abs. 2 RStV:</p>
    <p>
        Alessio Nocita<br />
        Quergasse 9<br />
        47051 Duisburg
    </p>
    
    <h2>Haftungsausschluss</h2>
    <h3>Haftung für Inhalte</h3>
    <p>
        Als Diensteanbieter sind wir gemäß Par. 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. 
        Nach Par. 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen 
        oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung 
        von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt 
        der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte 
        umgehend entfernen.
    </p>
    
    <h3>Haftung für Links</h3>
    <p>
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese 
        fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber 
        der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. 
        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten 
        ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir 
        derartige Links umgehend entfernen.
    </p>
    
    <h3>Urheberrecht</h3>
    <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, 
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung 
        des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. 
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden 
        Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen 
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
    </p>
    
    <h2>Datenschutzerklärung</h2>
    <h3>Datenschutz</h3>
    <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich 
        und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
    </p>
    <p>
        Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene 
        Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. 
        Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
    </p>
    <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. 
        Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
    </p>
    
    <h3>Cookies</h3>
    <p>
        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. 
        Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem 
        Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. 
        Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. 
        Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
    </p>
    <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, 
        die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des 
        Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
    </p>
    
    <h3>Server-Log-Files</h3>
    <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch 
        an uns übermittelt. Dies sind:
    </p>
    <ul>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
    </ul>
    <p>
        Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. 
        Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
    </p>
    
    <h3>Registrierung auf dieser Webseite</h3>
    <p>
        Sie können sich auf unserer Webseite registrieren, um zusätzliche Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten verwenden 
        wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der Registrierung 
        abgefragten Pflichtangaben müssen vollständig angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.
    </p>
    <p>
        Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die bei der Registrierung angegebene 
        E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
    </p>
    </>
}
{language === 'en' &&
<>
<p>Information according to sect. 5 TMG:</p>
      <p>
        Alessio Nocita<br />
        Quergasse 9<br />
        47051 Duisburg
      </p>
      <p>
        Contact:<br />

        Email: <a href="mailto:alessio@nocita.com">alessio@nocita.com</a>
      </p>
      <p>Responsible for the content according to sect. 55 Abs. 2 RStV:</p>
      <p>
        Alessio Nocita<br />
        Quergasse 9<br />
        47051 Duisburg
      </p>
      
      <h2>Disclaimer</h2>
      <h3>Liability for Content</h3>
      <p>
        As a service provider, we are responsible for our own content on these pages in accordance with § 7 Abs.1 TMG under the general laws. 
        However, according to sect. 8 to 10 TMG, we are not obligated to monitor transmitted or stored external information or to investigate 
        circumstances that indicate illegal activity. Obligations to remove or block the use of information under general laws remain unaffected. 
        However, liability in this regard is only possible from the time of knowledge of a specific infringement. Upon notification of such 
        violations, we will remove this content immediately.
      </p>
      
      <h3>Liability for Links</h3>
      <p>
        Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume 
        any liability for these external contents. The respective provider or operator of the sites is always responsible for the contents 
        of the linked sites. The linked sites were checked for possible legal violations at the time of linking. Illegal contents were not 
        recognizable at the time of linking. However, permanent content control of the linked sites is not reasonable without concrete evidence 
        of an infringement. Upon notification of violations, we will remove such links immediately.
      </p>
      
      <h3>Copyright</h3>
      <p>
        The contents and works created by the site operators on these pages are subject to German copyright law. The duplication, processing, 
        distribution, and any kind of exploitation outside the limits of copyright law require the written consent of the respective author or 
        creator. Downloads and copies of this site are only permitted for private, non-commercial use. As far as the content on this site was 
        not created by the operator, the copyrights of third parties are respected. In particular, contents of third parties are marked as such. 
        Should you nevertheless become aware of a copyright infringement, we request that you notify us accordingly. Upon notification of 
        violations, we will remove such content immediately.
      </p>
      
      <h2>Privacy Policy</h2>
      <h3>Data Protection</h3>
      <p>
        The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and 
        in accordance with the statutory data protection regulations and this privacy policy.
      </p>
      <p>
        The use of our website is usually possible without providing personal data. As far as personal data (for example, name, address or 
        e-mail addresses) are collected on our pages, this is always done on a voluntary basis as far as possible. These data will not be 
        passed on to third parties without your explicit consent.
      </p>
      <p>
        We would like to point out that data transmission over the Internet (e.g., communication by e-mail) can have security gaps. A complete 
        protection of the data against access by third parties is not possible.
      </p>
      
      <h3>Cookies</h3>
      <p>
        Some of the Internet pages use so-called cookies. Cookies do not damage your computer and do not contain viruses. Cookies serve to make 
        our offer more user-friendly, effective, and secure. Cookies are small text files that are stored on your computer and saved by your 
        browser. Most of the cookies we use are so-called "session cookies." They are automatically deleted after your visit. Other cookies 
        remain stored on your end device until you delete them. These cookies allow us to recognize your browser on your next visit.
      </p>
      <p>
        You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases, 
        exclude the acceptance of cookies for certain cases or generally, and activate the automatic deletion of cookies when closing 
        the browser. The functionality of this website may be limited when cookies are deactivated.
      </p>
      
      <h3>Server-Log-Files</h3>
      <p>
        The provider of the pages automatically collects and stores information in so-called server log files, which your browser automatically 
        transmits to us. These are:
      </p>
      <ul>
        <li>Browser type and version</li>
        <li>Operating system used</li>
        <li>Referrer URL</li>
        <li>Host name of the accessing computer</li>
        <li>Time of the server request</li>
      </ul>
      <p>
        These data cannot be assigned to specific persons. A combination of these data with other data sources is not made. We reserve the 
        right to check this data retrospectively if we become aware of specific indications for illegal use.
      </p>
      
      <h3>Registration on this Website</h3>
      <p>
        You can register on our website to use additional functions on the site. The data entered for this purpose will only be used for the 
        purpose of using the respective offer or service for which you have registered. The mandatory information requested during 
        registration must be provided in full. Otherwise, we will reject the registration.
      </p>
      <p>
        For important changes, such as the scope of the offer or technically necessary changes, we use the e-mail address provided during 
        registration to inform you in this way.
      </p>
      </>
        }

 </div>
    </>

 

    );
}

export default Impressum;