import avatar from '../assets/alessio-webdeveloper.png';
import { useLoaderData } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './Home.module.sass';

import TagList from '../components/TagList';
import DateList from '../components/DateList';

const Home = () => {
    const { posts } = useLoaderData();
    const featured_post = posts[0];

    const convertLineBreaks = (html) => {
        return html.replace(/\n/g, '<br />');
    }

    const stripHtmlTags = (html) => { 
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
      }


    return (
        <main className={styles.homeMain}>
            <h2 className={styles.welcomeTitle}>Welcome to my blog!<br /> I am Alessio, <br />media producer, artist, <br />and curious thinker.</h2>
            <section className={styles.welcomeBlock}>
                <div className={styles.avatarBlock}>
                    <img className={styles.avatarImage} src={avatar} alt="Alessio Nocita, the author" />
                </div>
                <div className={styles.welcomeText}>
                <p>Welcome to my little blog!</p>
                <p>This is a space about expressions, thoughts, experiences, and ideas. I dont have the pretension to inform or make scientific research here (there are better dedicated spaces for that). I hope just to be able to share interesting thoughts, creations and emotions and to put some elaboration about topics I find relevant or interesting. As this is not a social media platform, nothing here is about getting likes, shares, subscribes, [money] or to be popular. This will give me (and you as reader and, if you want, as commenter) the freedom to express yourself without boundaries other than respect, exploring thoughts that are not typically discussed on popularity platforms. I hope you will enjoy this space and this freedom.</p>

                </div>
            </section>
            <hr />
            <section className={styles.latestPosts} id="latest-posts">
                <h3>Featured post</h3>
                <article className={styles.post}>
                    <div className={styles.postContent}>
                        <h1 className={styles.postTitle}><strong>{featured_post.title}</strong></h1>
                        <img className={styles.featuredPostImage} src={featured_post.image} alt="The picture of the first post" />
                        <p><em>{featured_post.author}</em></p>
                        <p>{featured_post.date}</p>
                        <p className={styles.postText} dangerouslySetInnerHTML={{ __html: convertLineBreaks(stripHtmlTags(featured_post.content).split(" ").slice(0, 75).join(" ")) + '...' }}></p>
                        <Link to={`/post/${featured_post.slug}`}>Read more...</Link>
                    </div>
                </article>
            </section>
        </main>
    );
}

export default Home;
