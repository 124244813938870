import styles from './DoubleSeparator.module.sass'

const DoubleSeparator = () => {
  return (
    <div className={styles.doubleSeparator}>
        <hr className={styles.largeSeparator}/>
        <hr className= {styles.smallSeparator}/> 
    </div>
  );
}

export default DoubleSeparator;